import { createStore } from 'vuex';

export default createStore({
	state: {
		userInfo: "",
	},
	getters: {

	},
	mutations: {
		saveInfo(state, params) {
			state.userInfo = params;
		}
	},
	actions: {
	},
	modules: {
	}
})