import api from "./api";

// 登录
const login = (params : any) => {
	api.request({
		url: '/web/login',
		method: "POST",
		isLoading: '登录中...',
		data: params.data,
		success: (res : any) => {
			localStorage.setItem("token", res.data.token);
			const a = setTimeout(() => {
				clearTimeout(a);
				params.success && typeof params.success === 'function' && params.success(res.data);
			}, 500);
		},
		fail: (err : any) => {
			params.fail && typeof params.fail === 'function' && params.fail(err);
		}
	});
};

export default {
	login: login,
}