import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element';
import './styles/element/index.scss';

// 公共样式
import './assets/css/item.css';
import './assets/css/togyUI.css';

// 自定义全局组件
import PublicView from './components/Components';

// 全局挂载请求
import api from './api/api';
import CommonApi from './api/CommonApi';

const app = createApp(App);
installElementPlus(app);
app.config.globalProperties.$api = api;
app.config.globalProperties.$CommonApi = CommonApi;
app.use(store).use(router).use(PublicView).mount('#app');