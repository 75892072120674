import { App } from 'vue'
import TipsPop from '@/components/TipsPop.vue';
import FilePop from '@/components/upLoadFilePop.vue';
import BackIcon from '@/components/BackIcon.vue';
/** 
export default {
  install(app: App) {
	app.component("Home", HomeVue);
	app.component("Helloword", HelloWorldVue)
  }
}
*/
const components = [
	{ title: 'TipsPop', componentName: TipsPop },
	{ title: 'FilePop', componentName: FilePop },
	{ title: 'BackIcon', componentName: BackIcon },
]
export default {
	install(app : App) {
		components.forEach(item => {
			// 通过循环遍历数据批量注册组件
			app.component(item.title, item.componentName)
		})
	}
}