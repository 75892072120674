import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes : Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'login',
		meta: {
			title: '登录',
		},
		component: () => import('../views/LoginView.vue')
	}, {
		path: '/Home',
		name: 'home',
		component: () => import('../views/HomeView.vue'),
		children: [{
			path: '/Index',
			name: 'index',
			meta: {
				title: '首页',
			},
			component: () => import('../views/IndexView.vue'),
		}, {
			path: '/GoodManagement',
			name: 'GoodManagement',
			meta: {
				title: '商品管理',
			},
			component: () => import('../views/GoodManagement/GoodManagement.vue'),
		}, {
			path: '/Reservation/List',
			name: 'list',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/ListView.vue'),
		}, {
			path: '/Reservation/SingleItem',
			name: 'singleitem',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/SingleItem.vue'),
		}, {
			path: '/Reservation/SelfBuiltStorage',
			name: 'selfbuiltstorage',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/SelfBuiltStorage.vue'),
		}, {
			path: '/Reservation/ProductLibrary',
			name: 'ProductLibrary',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/ProductLibrary.vue'),
		}, {
			path: '/Reservation/OneSixEightEight',
			name: 'onesixeighteight',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/OneSixEightEight.vue'),
		}, {
			path: '/Reservation/OneSixEightEightSingle',
			name: 'onesixeighteightsingle',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/OneSixEightEightSingle.vue'),
		}, {
			path: '/Reservation/OneSixEightEightGroup',
			name: 'onesixeighteightgroup',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/OneSixEightEightGroup.vue'),
		}, {
			path: '/Reservation/Detail',
			name: '/Reservation/Detail',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/ListDetail.vue'),
		}, {
			path: '/Reservation/CombinationDetail',
			name: '/Reservation/CombinationDetail',
			meta: {
				title: '入库预约单',
			},
			component: () => import('../views/Reservation/CombinationDetail.vue'),
		}, {
			path: '/Outbound/List',
			name: '/Outbound/List',
			meta: {
				title: '出库预约单',
			},
			component: () => import('../views/Outbound/ListView.vue'),
		}, {
			path: '/Outbound/Detail',
			name: '/Outbound/Detail',
			meta: {
				title: '出库预约单',
			},
			component: () => import('../views/Outbound/ListDetail.vue'),
		}, {
			path: '/Outbound/FbaDetail',
			name: '/Outbound/FbaDetail',
			meta: {
				title: '出库预约单',
			},
			component: () => import('../views/Outbound/FbaDetail.vue'),
		}, {
			path: '/Outbound/SmallOutbound',
			name: '/Outbound/SmallOutbound',
			meta: {
				title: '出库预约单',
			},
			component: () => import('../views/Outbound/SmallOutbound.vue'),
		}, {
			path: '/Outbound/FbaSmallOutbound',
			name: '/Outbound/FbaSmallOutbound',
			meta: {
				title: '出库预约单',
			},
			component: () => import('../views/Outbound/FbaSmallOutbound.vue'),
		}, {
			path: '/Outbound/OrderFreightSelection',
			name: '/Outbound/OrderFreightSelection',
			meta: {
				title: '出库预约单',
			},
			component: () => import('../views/Outbound/OrderFreightSelection.vue'),
		}, {
			path: '/Outbound/FbaOutbound',
			name: '/Outbound/FbaOutbound',
			meta: {
				title: '出库预约单',
			},
			component: () => import('../views/Outbound/FbaOutbound.vue'),
		}, {
			path: '/Refund/List',
			name: '/Refund/List',
			meta: {
				title: '退货单',
			},
			component: () => import('../views/Refund/ListView.vue'),
		}, {
			path: '/Refund/ReturnOrderDetail',
			name: '/Refund/ReturnOrderDetail',
			meta: {
				title: '退货单',
			},
			component: () => import('../views/Refund/ReturnOrderDetail.vue'),
		}, {
			path: '/OperationInWarehouse/List',
			name: '/OperationInWarehouse/List',
			meta: {
				title: '库内商品',
			},
			component: () => import('../views/OperationInWarehouse/ListView.vue'),
		}, {
			path: '/OperationInWarehouse/SingleItem',
			name: '/OperationInWarehouse/SingleItem',
			meta: {
				title: '库内单品加工单',
			},
			component: () => import('../views/OperationInWarehouse/SingleItem.vue'),
		}, {
			path: '/OperationInWarehouse/SingleDetail',
			name: '/OperationInWarehouse/SingleDetail',
			meta: {
				title: '库内单品加工单',
			},
			component: () => import('../views/OperationInWarehouse/SingleDetail.vue'),
		}, {
			path: '/OperationInWarehouse/CombinationOrder',
			name: '/OperationInWarehouse/CombinationOrder',
			meta: {
				title: '库内组合单',
			},
			component: () => import('../views/OperationInWarehouse/CombinationOrder.vue'),
		}, {
			path: '/OperationInWarehouse/AddCombinationOrder',
			name: '/OperationInWarehouse/AddCombinationOrder',
			meta: {
				title: '库内组合单',
			},
			component: () => import('../views/OperationInWarehouse/AddCombinationOrder.vue'),
		}, {
			path: '/OperationInWarehouse/CombinationOrderDetail',
			name: '/OperationInWarehouse/CombinationOrderDetail',
			meta: {
				title: '库内组合单',
			},
			component: () => import('../views/OperationInWarehouse/CombinationOrderDetail.vue'),
		}, {
			path: '/AuthManage/OneSixEightEight',
			name: '/AuthManage/OneSixEightEight',
			meta: {
				title: '1688授权',
			},
			component: () => import('../views/AuthManage/OneSixEightEight.vue'),
		}, {
			path: '/AuthManage/LeadingStar',
			name: '/AuthManage/LeadingStar',
			meta: {
				title: '领星授权',
			},
			component: () => import('../views/AuthManage/LeadingStar.vue'),
		}, {
			path: '/FinancialData/FreightTrial',
			name: '/FinancialData/FreightTrial',
			meta: {
				title: '运费试算',
			},
			component: () => import('../views/FinancialData/FreightTrial.vue'),
		}, {
			path: '/FinancialData/FinancialDetails',
			name: '/FinancialData/FinancialDetails',
			meta: {
				title: '财务明细',
			},
			component: () => import('../views/FinancialData/FinancialDetails.vue'),
		}, {
			path: '/FinancialData/PromotionIncome',
			name: '/FinancialData/PromotionIncome',
			meta: {
				title: '推广收入',
			},
			component: () => import('../views/FinancialData/PromotionIncome.vue'),
		}, {
			path: '/Mine/MineView',
			name: 'mine',
			meta: {
				title: '个人设置',
			},
			component: () => import('../views/Mine/MineView.vue'),
		}, {
			path: '/Mine/SubAccount',
			name: 'mine/SubAccount',
			meta: {
				title: '子账号管理',
			},
			component: () => import('../views/Mine/SubAccount.vue'),
		}, {
			path: '/UserAccessControl',
			name: 'userAccessControl',
			meta: {
				title: '访问权限管理'
			},
			component: () => import('../views/Mine/UserAccessControl.vue')
		}],
	}, {
		path: '/about',
		name: 'about',
		component: () => import('../views/AboutView.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

/* 路由发生变化修改页面title */
router.beforeEach((to : any) => {
	if (to.meta.title) {
		document.title = '美鸥云仓-' + to.meta.title;
	}
})

export default router