// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../styles/publicscss/publicscss.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-btn[data-v-090c2422]{background:rgba(255,106,0,.05);color:#ff6a00}.reset-btn[data-v-090c2422],.sure-btn[data-v-090c2422]{width:110px;height:36px;border-radius:4px;font-size:13px;border:0!important}.sure-btn[data-v-090c2422]{background:#ff6a00;color:#fff}.up-area[data-v-090c2422]{height:107px;background:#f7f7f7}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
