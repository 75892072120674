<template>
	<div class="main">
		<router-view></router-view>
	</div>
</template>

<script>
	import config from '@/api/config';
	
	export default {
		name: 'App',
		created() {
			let href = window.location.href;
			if (href.indexOf("localhost") > -1 || href.indexOf("togy") > -1) {
				// 本地+内部测试版本域名
				config.request_host = "https://api.sharkbox.cn";
				config.uploadFile_host = "https://api.sharkbox.cn";
				// config.request_host = "https://api-dev.sharkbox.cn";
				// config.uploadFile_host = "https://api-dev.sharkbox.cn";
			} else if (href.indexOf("sharkbox") > -1) {
				// 正式版域名
				config.request_host = "https://api-dev.sharkbox.cn";
				config.uploadFile_host = "https://api-dev.sharkbox.cn";
			}
		}
	}
</script>

<style lang="scss">
	html {
		width: 100%;
		height: 100vh;
	}
	
	* {
		margin: 0;
		padding: 0;
	}
	
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
	
	.main {
		width: 100%;
		height: 100vh;
	}
	
	input {
		border: 0;
		outline: none;
		background: transparent;
	}
</style>